import moment from "moment";
import { useIntl } from "react-intl";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useTheme } from "styled-components";
import AreaChart from "../../../components/graphs/area";
import PieChart from "../../../components/graphs/pie";
import { useFeatureToggles } from "../../../context/feature-toggles";
import { Features } from "../../../enums/features";
import { CustomChartType } from "../../../types/custom-charts";
import useMediaQuery from "../../../utils/hooks/use-media-query";
import ChartTooltip from "../../shared/chart-tooltip/chart-tooltip";
import { BarYTick } from "./components/bar-tick";
import { BarTooltip } from "./components/bar-tooltip";
import { ChartsContainer, Column, Container, Description, Row, Wrapper } from "./custom-chart.styles";

import Styled from "../../../components/ui/styled";
import { ChartTypes } from "../../../enums/charts";
import LegendList from "./components/legend-list";
import { NoData } from "./components/no-data";

const PIE_COLORS = [
	"#F49E37",
	"#FFC887",
	"#FFB55D",
	"#D97D0F",
	"#AA5E03",
].reverse();

const Chart = ({ chartType, code, description, label, value, datasets, legend, ...props }: CustomChartType) => {
	const intl = useIntl();
	const { isFeatureActive } = useFeatureToggles();
	const isMobile = useMediaQuery('(max-width: 767px)');
	const theme = useTheme();

	const labelKey = label.code;
	const valueKey = value.code;
	let hasData = false;
	let chartData = [];

	if (datasets.length === 0 && isFeatureActive(Features.HideEmptyCharts)) return null;

	switch (chartType.toLowerCase()) {
		case ChartTypes.HorizontalBar:
			const fixedData = datasets.map((entry, index) => ({
				...entry,
				[value.code]: (() => {
					if (value.displayMeasurementType === 'Minutes')
						return entry[value.code] / 1000 / 60;

					return entry[value.code]
				})()
			}));

			const leftGap: number = 70;
			const margins = isMobile ? { top: 0, right: 0, bottom: -20, left: leftGap } : { top: 30, right: 40, bottom: 30, left: leftGap };

			return <ResponsiveContainer width="100%" height={fixedData.length * 90}>
				<BarChart
					data={fixedData}
					layout="vertical"
					barSize={8}
					margin={margins}>
					<CartesianGrid
						horizontal={false}
						strokeDasharray="10 10"
						stroke={theme.colors.gray[200]}
					/>
					<XAxis
						type="number"
						axisLine={false}
						dataKey={value.code}
						dy={10}
						tickSize={0}
						tick={false}
						domain={[0, 'dataMax']}
					/>
					<YAxis
						type="category"
						axisLine={false}
						dataKey={label.code}
						fill={theme.colors.gray[50]}
						textAnchor="end"
						interval={0}
						tickSize={0}
						dx={-10}
						tick={<BarYTick width={leftGap + 10} fill={theme.colors.white} />}
					/>
					{legend && <Legend fill={theme.colors.gray[100]} content={<LegendList legend={legend} />} />}
					<Tooltip
						isAnimationActive={false}
						separator={": "}
						cursor={false}
						content={<BarTooltip value={value} />}
					/>
					<Bar dataKey={value.code} fill={theme.colors.primary.yellow[400]} />
				</BarChart>
			</ResponsiveContainer>
		case ChartTypes.Area:
			chartData = datasets && datasets.map((item: any) => {
				let value = item[valueKey];
				let name = item[labelKey];

				if (label.currentMeasurementType === "DateTime") {
					name = isMobile ? moment(name).format("D/MM") : moment(name).format("Do MMM YY");
				}

				return {
					name,
					value,
					labelKey,
					valueKey
				}
			}) || [];

			hasData = chartData.length > 0;

			return hasData && <AreaChart
				tooltipContent={ChartTooltip}
				interval={props?.xAxisInterval || 7}
				data={chartData}
				xAxisLabel={intl.formatMessage({ id: labelKey, defaultMessage: label.description })} /> ||
				<NoData />


		case ChartTypes.Pie:
			chartData = datasets && datasets.map((item: any) => {
				let value = item[valueKey];
				let name = item[labelKey];

				return {
					name,
					value
				}
			}) || [];

			hasData = chartData.length > 0;

			return hasData && <PieChart
				data={chartData}
				colors={PIE_COLORS} /> || <NoData />

		default:
			return null;
	}
}

type ChartSection = {
	section: string,
	order: number,
	charts: CustomChartType[]
}

const CustomCharts = ({ charts }: { charts: CustomChartType[] }) => {

	const { isFeatureActive } = useFeatureToggles();

	const chartsGrouped = charts.reduce((acc: ChartSection[], chart: CustomChartType) => {
		const { gridView } = chart;
		const section = gridView && gridView.groupSection || 'misc';
		const order = gridView ? gridView.groupOrder : Infinity;

		const index = acc.findIndex((el: ChartSection) => el.section === section);

		if (index < 0) {
			acc.push({ section, order, charts: [chart] });
			return acc;
		}

		acc[index].charts.push(chart);
		return acc;
	}, []).sort((a: ChartSection, b: ChartSection) => a.order > b.order ? 1 : -1).map(({ charts, ...otherProps }: ChartSection) => ({
		...otherProps,
		charts: charts.reduce((acc: CustomChartType[][], chart: CustomChartType) => {

			const { gridView } = chart;
			const column = gridView ? gridView.columnPosition : 0;
			const row = gridView ? gridView.rowPosition : 0;

			if (!gridView) {
				acc.push([chart]);
				return acc;
			}

			if (!acc[row]) acc[row] = [];
			acc[row][column] = chart;

			return acc;
		}, [])
	}));

	return <ChartsContainer>
		{chartsGrouped.map(({ charts, section }: { charts: CustomChartType[][], section: string }) =>
			<Container key={`container-for-${section}`}>
				{charts.map((row: CustomChartType[]) =>
					<Row>
						{row.map((chart: CustomChartType) => {
							const hasData = chart.datasets.length > 0;

							if (!hasData && isFeatureActive(Features.HideEmptyCharts)) return null;

							return <Column empty={!hasData} style={{ flex: 1 / row.length }}>
								<Description>{chart.description}</Description>
								<Wrapper>
									{hasData ? <Chart {...chart} /> : <Styled padding="1rem 0" textAlign="center">
										<NoData />
									</Styled>}
								</Wrapper>
							</Column>
						}
						)}
					</Row>)}
			</Container>)}
	</ChartsContainer>
}

export default CustomCharts;
